import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import API_URL from "../config";

export default function Availability() {
  const [availabilitys, setAvailabilitys] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadAvailabilitys();
  }, []);

  const loadAvailabilitys = async () => {
    try {
      const result = await axios.get(`${API_URL}/api/public/availability`);
      setAvailabilitys(result.data);
    } catch (error) {
      console.error("Erro ao carregar os horários:", error);
    }
  };

  const handleView = (availabilityId) => {
    navigate(
      `/aHR0cHM6Ly9vcmxhYmVhY2h0ZW5uaXNvdXJpbmhvcy5jb20uYnIvYWRtaW4vYXZhaWxhYmlsaXR5/${availabilityId}`
    );
  };

  const handleEdit = (availabilityId) => {
    navigate(
      `/aHR0cHM6Ly9vcmxhYmVhY2h0ZW5uaXNvdXJpbmhvcy5jb20uYnIvYWRtaW4vYXZhaWxhYmlsaXR5/editar-horario/${availabilityId}`
    );
  };

  const handleDelete = async (availabilityId) => {
    const confirmDelete = window.confirm(
      "Tem certeza que deseja excluir esta quadra?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(
          `${API_URL}/api/public/availability/${availabilityId}`
        );
        loadAvailabilitys(); // Recarrega os dados após a exclusão
      } catch (error) {
        console.error("Erro ao excluir a quadra:", error);
      }
    }
  };

  // Mapeamento de dias da semana para português e ordem correta
  const weekDaysTranslation = {
    MONDAY: { name: "Segunda-feira", order: 1 },
    TUESDAY: { name: "Terça-feira", order: 2 },
    WEDNESDAY: { name: "Quarta-feira", order: 3 },
    THURSDAY: { name: "Quinta-feira", order: 4 },
    FRIDAY: { name: "Sexta-feira", order: 5 },
    SATURDAY: { name: "Sábado", order: 6 },
    SUNDAY: { name: "Domingo", order: 7 },
  };

  // Ordenar por dia da semana e depois por horário de início
  const sortedAvailabilitys = [...availabilitys].sort((a, b) => {
    const dayOrderA = weekDaysTranslation[a.weekDay]?.order || 8; // Se não existir, coloca no final
    const dayOrderB = weekDaysTranslation[b.weekDay]?.order || 8;

    if (dayOrderA !== dayOrderB) {
      return dayOrderA - dayOrderB;
    }

    return a.startTime.localeCompare(b.startTime);
  });

  return (
    <div className="container">
      <section style={{ marginTop: "30px" }}>
        <h2>Horários</h2>
      </section>
      {/*<div className="new-availability">
        <Link
          className="btn btn-outline-dark"
          to="/admin/availability/novo-horario"
        >
          Novo Horário
        </Link>
      </div>*/}
      <table className="table border shadow">
        <thead>
          <tr>
            <th scope="col">Dia da Semana</th>
            <th scope="col">Horário</th>
            <th scope="col">Status</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {sortedAvailabilitys.map((availability) => (
            <tr key={availability.id}>
              <td>
                {weekDaysTranslation[availability.weekDay]?.name ||
                  availability.weekDay}
              </td>
              <td>{availability.startTime + " - " + availability.endTime}</td>
              <td>{availability.active ? "Ativa" : "Inativa"}</td>
              <td>
                <button
                  className="btn btn-primary mx-2"
                  onClick={() => handleView(availability.id)}
                >
                  Ver
                </button>
                <button
                  className="btn btn-outline-primary mx-2"
                  onClick={() => handleEdit(availability.id)}
                >
                  Editar
                </button>
                {/*<button
                  className="btn btn-danger mx-2"
                  onClick={() => handleDelete(availability.id)}
                >
                  Excluir
                </button>*/}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
