import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API_URL from "../config";

export default function EditAvailability() {
  const { availabilityId } = useParams();
  const [availability, setAvailability] = useState({
    name: "",
    price: "",
    active: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    loadAvailabilityDetails();
  }, []);

  const loadAvailabilityDetails = async () => {
    const result = await axios.get(
      `${API_URL}/api/public/availability/${availabilityId}`
    );
    setAvailability(result.data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAvailability((prev) => ({
      ...prev,
      [name]: name === "active" ? e.target.checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${API_URL}/api/public/availability/${availabilityId}`,
        availability
      );
      navigate(
        "/aHR0cHM6Ly9vcmxhYmVhY2h0ZW5uaXNvdXJpbmhvcy5jb20uYnIvYWRtaW4vYXZhaWxhYmlsaXR5"
      );
    } catch (error) {
      console.error("Erro ao atualizar a quadra:", error);
    }
  };

  // Mapeamento para tradução dos dias da semana
  const weekDaysTranslation = {
    MONDAY: "Segunda-feira",
    TUESDAY: "Terça-feira",
    WEDNESDAY: "Quarta-feira",
    THURSDAY: "Quinta-feira",
    FRIDAY: "Sexta-feira",
    SATURDAY: "Sábado",
    SUNDAY: "Domingo",
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Editar Horário</h2>
      <form onSubmit={handleSubmit} className="border p-4 rounded shadow">
        <div className="mb-3">
          <label className="form-label">Dia da Semana</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={
              weekDaysTranslation[availability.weekDay] || availability.weekDay
            }
            readOnly
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Horário</label>
          <input
            type="text"
            className="form-control"
            name="price"
            value={availability.startTime + " - " + availability.endTime}
            readOnly
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            <input
              type="checkbox"
              name="active"
              checked={availability.active}
              onChange={handleChange}
              className="form-check-input me-2"
            />
            Ativa
          </label>
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary me-2">
            Salvar
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              navigate(
                "/aHR0cHM6Ly9vcmxhYmVhY2h0ZW5uaXNvdXJpbmhvcy5jb20uYnIvYWRtaW4vYXZhaWxhYmlsaXR5"
              )
            }
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}
