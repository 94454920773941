import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import AlertUtil from "../util/AlertUtil";
import moment from "moment";
import API_URL from "../config";

export default function Booking() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [options, setOptions] = useState([]);
  const [courts, setCourts] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState(null);
  const courtsRef = useRef(null);
  const [customerId, setCustomerId] = useState(null);
  const [reservationCount, setReservationCount] = useState(0);
  const [reservationId, setReservationId] = useState(null);
  const [courtId, setCourtId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [triggerAlert, setTriggerAlert] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [selectedCourtId, setSelectedCourtId] = useState(null);
  const registerButtonRef = useRef(null);
  const endOfPageRef = useRef(null);
  const [triggerAlertSuccess, setTriggerAlertSuccess] = useState(false);
  const [triggerAlertInfo, setTriggerAlertInfo] = useState(false);
  const [alertMessageSuccess, setAlertMessageSuccess] = useState("");
  const [alertMessageInfo, setAlertMessageInfo] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    cpf: "",
  });

  const [showForm, setShowForm] = useState(null); // null, "register", "login"

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setOptions([]); // Limpa os horários ao selecionar uma nova data
    setCourts([]);
    setSelectedCourt(null);

    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    try {
      const response = await axios.get(
        `${API_URL}/api/public/availableTimes?date=${formattedDate}`
      );

      let options = response.data;

      // Verifica se o dia selecionado é sábado (6) ou domingo (0)
      if (dayjs(date).day() === 6) {
        options = [
          ...options,
          {
            id: "day-use",
            startTime: "SÁBADO",
            endTime: "DAY USE NOS DEMAIS HORÁRIOS",
            description:
              "Todo SÁBADO, 08:30h às 21:30h - DAY USE R$20,00 (grátis para alunos matriculados).",
          },
        ];
      } else if (dayjs(date).day() === 0) {
        options = [
          ...options,
          {
            id: "day-use",
            startTime: "DOMINGO",
            endTime: "DAY USE NOS DEMAIS HORÁRIOS",
            description:
              "Todo DOMINGO, 15:30h às 21:30h - DAY USE R$20,00 (grátis para alunos matriculados).",
          },
        ];
      }

      setOptions(options);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  const registerFormRef = useRef(null);
  const loginFormRef = useRef(null);

  const handleRegisterButtonClick = () => {
    setShowForm("register");
    setTimeout(() => {
      if (registerFormRef.current) {
        registerFormRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleLoginButtonClick = () => {
    setShowForm("login");
    setTimeout(() => {
      if (loginFormRef.current) {
        loginFormRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleOptionClick = async (idAvailability) => {
    if (selectedDate) {
      setCourts([]); // Limpa as quadras ao selecionar um novo horário
      setSelectedCourt(null);
      setSelectedOptionId(idAvailability);
      const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
      try {
        const response = await axios.get(
          `${API_URL}/api/public/availableCourts?date=${formattedDate}&idAvailability=${idAvailability}`
        );
        setCourts(response.data);
        if (courtsRef.current) {
          courtsRef.current.scrollIntoView({ behavior: "smooth" });
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    }
  };

  const handleCourtSelect = (court) => {
    setSelectedCourt(court);
    setShowForm(null); // Redefinir a exibição do formulário ao selecionar uma nova quadra

    setTimeout(() => {
      if (endOfPageRef.current) {
        endOfPageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "phone") {
      // Remove todos os caracteres não numéricos
      let input = value.replace(/\D/g, "");
      if (input.length > 11) input = input.slice(0, 11);

      // Formata o número de telefone
      formattedValue = input
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2");
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/api/public/customer`, {
        name: formData.name,
        phone: formData.phone,
        document: formData.cpf,
        reservationCount: 0,
      });
      setCustomerId(response.data.id);
      setReservationCount(0);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        console.error("Erro ao cadastrar o cliente: Código 409", error);
        alert("Já existe um cliente cadastrado com este CPF.");
      } else {
        console.error("Erro ao cadastrar o cliente.", error);
        alert("Erro ao cadastrar o cliente.");
      }
    }
    console.log(formData);
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${API_URL}/api/public/customer/document/${formData.cpf}`
      );
      setCustomerId(response.data.id);
      setReservationCount(response.data.reservationCount);
    } catch (error) {
      console.error("Erro ao buscar cliente:", error);
      alert("Erro ao buscar cliente.");
    }
  };

  useEffect(() => {
    if (customerId !== null) {
      handleReservationSubmit();
    }
  }, [customerId]);

  const handleReservationSubmit = async () => {
    try {
      const formattedDate = selectedDate.toISOString().substring(0, 10);
      const alertDate = selectedDate.format("DD/MM/YYYY");
      const reservationStartTime = options.find(
        (option) => option.id === selectedCourt.idAvailability
      );
      await axios.post(`${API_URL}/api/public/reservation`, {
        date: formattedDate,
        idCourt: selectedCourt.id,
        idAvailability: selectedCourt.idAvailability,
        idCustomer: customerId,
        status: "APPROVED",
      });
      setAlertMessageSuccess(
        "Reserva efetuada com sucesso para o dia " +
          alertDate +
          " às " +
          reservationStartTime.startTime
      );
      setTriggerAlertSuccess(true);

      // Chama o endpoint para obter o reservationCount atualizado
      const response = await axios.get(
        `${API_URL}/api/public/customer/document/${formData.cpf}`
      );
      const updatedReservationCount = response.data.reservationCount;

      setReservationCount(updatedReservationCount);

      setAlertMessageInfo(
        "Programa de fidelidade Orla: " +
          updatedReservationCount +
          " reservas realizadas. Quando acumular 10 reservas em 'Minhas Reservas' envie um print via WhatsApp e ganhe uma reserva grátis."
      );
      setTriggerAlertInfo(true);
      setOptions([]); // Limpa os horários ao selecionar uma nova data
      setCourts([]);
      setSelectedCourt(null);
      setShowForm(null);
      setCustomerId(null);
    } catch (error) {
      console.error("Erro ao reservar:", error);
    }
  };

  const disabledDates = [
    dayjs("2024-10-18"),
    dayjs("2024-10-19"),
    dayjs("2024-10-20"),
  ];

  const shouldDisableDate = (date) => {
    return disabledDates.some((disabledDate) =>
      date.isSame(disabledDate, "day")
    );
  };

  return (
    <div className="Booking">
      <div className="calendar-container">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DateCalendar
            disablePast={true}
            onChange={handleDateChange}
            minDate={dayjs("2024-10-07")}
            shouldDisableDate={shouldDisableDate}
          />
        </LocalizationProvider>
        {options.length > 0 && (
          <div className="options-list">
            {options.map((option) => (
              <button
                key={option.id}
                onClick={() => handleOptionClick(option.id)}
                className={option.id === selectedOptionId ? "selected" : ""}
              >
                {`${option.startTime} - ${option.endTime}`}
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="courts-container" ref={courtsRef}>
        {courts.length === 0 ? (
          <p>Selecione uma data e horário acima.</p>
        ) : (
          <ul className="courts-list">
            {courts.map((court) => (
              <li key={court.id}>
                <button
                  onClick={() => handleCourtSelect(court)}
                  className={court === selectedCourt ? "selected" : ""}
                >
                  {`${court.courtName}`}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
      <AlertUtil
        triggerAlert={triggerAlertSuccess}
        alertMessage={alertMessageSuccess}
        alertType="success"
        onClose={() => setTriggerAlertSuccess(false)}
      />

      <AlertUtil
        triggerAlert={triggerAlertInfo}
        alertMessage={alertMessageInfo}
        alertType="info"
        onClose={() => setTriggerAlertInfo(false)}
      />

      {selectedCourt && (
        <>
          <div className="form-selection">
            <button ref={registerButtonRef} onClick={handleRegisterButtonClick}>
              Me Cadastrar
            </button>
            <button onClick={handleLoginButtonClick}>Já possuo cadastro</button>
          </div>
          {showForm === "register" && (
            <form
              onSubmit={handleRegisterSubmit}
              className="form-container"
              ref={registerFormRef}
            >
              <h3 style={{ textAlign: "center" }}>Cadastro</h3>
              <div>
                <label>
                  Nome:
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              {/*<div>
                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>*/}
              <div>
                <label>
                  Telefone:
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  CPF:
                  <input
                    type="text"
                    name="cpf"
                    value={formData.cpf}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              <button type="submit">Cadastrar e Reservar</button>
            </form>
          )}
          {showForm === "login" && (
            <form
              onSubmit={handleLoginSubmit}
              className="form-container"
              ref={loginFormRef}
            >
              <h3 style={{ textAlign: "center" }}>Login</h3>
              <div>
                <label>
                  CPF:
                  <input
                    type="text"
                    name="cpf"
                    value={formData.cpf}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              <button type="submit">Reservar</button>
            </form>
          )}
        </>
      )}
      <div ref={endOfPageRef}></div>
    </div>
  );
}
