import axios from "axios";
import { useState, useEffect } from "react";
import API_URL from "../config";
import { useNavigate } from "react-router-dom";

export default function EditAdditionalInfo() {
  const [additionalInfo, setAdditionalInfo] = useState(""); // Estado para armazenar o conteúdo
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdditionalInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/public/frontItems`);
        const additionalInfoItem = response.data.find(
          (item) => item.key === "ADDITIONAL_INFO"
        );
        if (additionalInfoItem) {
          setAdditionalInfo(additionalInfoItem.keyValue); // Armazena o valor da chave "ADDITIONAL_INFO"
        }
      } catch (error) {
        console.error("Erro ao carregar as informações adicionais:", error);
      } finally {
        setLoading(false); // Finaliza o carregamento
      }
    };

    fetchAdditionalInfo();
  }, []);

  const handleChange = (e) => {
    setAdditionalInfo(e.target.value); // Atualiza o valor conforme o usuário edita
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${API_URL}/api/public/frontItems/2`, {
        key: "ADDITIONAL_INFO",
        keyValue: additionalInfo, // Envia o conteúdo editado
      });
      navigate("/YWRtaW4vZWRpdC1hZGRpdGlvbmFsLWluZm8="); // Redireciona após salvar
    } catch (error) {
      console.error("Erro ao salvar as informações:", error);
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Editar Informações Adicionais</h2>
      <form onSubmit={handleSubmit} className="border p-4 rounded shadow">
        <div className="mb-3">
          <label className="form-label">Informações Adicionais</label>
          <textarea
            className="form-control"
            rows="10"
            value={additionalInfo}
            onChange={handleChange}
            required
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary me-2">
            Salvar
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/YWRtaW4vZWRpdC1hZGRpdGlvbmFsLWluZm8=")}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}
