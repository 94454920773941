import axios from "axios";
import { useState, useEffect } from "react";
import API_URL from "../config"; // Certifique-se de ter o arquivo de configuração com a URL da API

export default function AdditionalInfo() {
  const [additionalInfo, setAdditionalInfo] = useState(""); // Armazena a informação para exibição
  const [loading, setLoading] = useState(true); // Estado para controle de carregamento

  useEffect(() => {
    const fetchAdditionalInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/public/frontItems`);
        const additionalInfoItem = response.data.find(
          (item) => item.key === "ADDITIONAL_INFO"
        );
        if (additionalInfoItem) {
          setAdditionalInfo(additionalInfoItem.keyValue); // Armazena o valor da chave "ADDITIONAL_INFO"
        }
      } catch (error) {
        console.error("Erro ao carregar as informações adicionais:", error);
      } finally {
        setLoading(false); // Finaliza o carregamento
      }
    };

    fetchAdditionalInfo();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="additional-info">
      <div dangerouslySetInnerHTML={{ __html: additionalInfo }} />
    </div>
  );
}
